<script>
export default {
  created() {
    localStorage.setItem("admin", "true");
    var _g = this;
    $.post(api + "/auth/check", {
      jwt: window.location.href.split("join/")[1].split("/to")[0],
    })
      .then(function (data) {
        data = JSON.parse(data);
        localStorage.setItem("user", JSON.stringify(data.response));
        var l = "";
        if (
          window.location.href.split("/to/")[1].replaceAll("---", "/") != "/"
        ) {
          l = "/";
        }
        _g.$router.push(
          l + window.location.href.split("/to/")[1].replaceAll("---", "/")
        );
        localStorage.setItem("lang", "ar");
        setTimeout(() => {
          location.reload();
        }, 500);
      })
      .catch(function (e) {
        alert("error" + e);
      });
  },
};
</script>
